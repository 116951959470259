import React from 'react';

/**
 * Renders the preloader
 */
const Loader = ({style}:any) => {

    return (
        <div className="preloader d-flex align-items-center justify-content-center" id="preloader" style={style}>
            <div className="status" id="status">
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
