import React, { createContext, useContext, useState } from "react";

interface ILoading {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const LoadingContext = createContext({} as ILoading);

const LoadingProvider: React.FC = ({ children }) => {
    const [loading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider
            value={{loading, setLoading}}
        >
            {children}
        </LoadingContext.Provider>
    );
};

const useLoading = () => {
    const loading = useContext(LoadingContext);
    return loading;
};

export { LoadingProvider, useLoading };
