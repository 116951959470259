import { APICore } from "./apiCore";

const api = new APICore();

const apiGet = <T = any>(url: string, queryStringObject?: any) => {
  if (queryStringObject) {
    return api.get<T>(`${url}`, queryStringObject);
  }
  return api.get<T>(url);
};

const apiGetFile = (url: string, queryStringObject?: any) => {
  if (queryStringObject) {
    return api.getFile(`${url}`, queryStringObject);
  }
  return api.getFile(url);
};

const apiCreate = <T = any>(url: string, params: any) => {
  return api.create<T>(url, params);
};

const apiUpdate = (url: string, params: any) => {
  return api.update(url, params);
};

const apiDelete = (url: string, params?: any) => {
  return api.delete(url, params);
};

const apiFile = (files: FileList) => {
  const formData = new FormData();
  Array.from(files).forEach((file) => {
    formData.append("arquivos", file);
  });

  formData.append("tipoarquivo_id", "1");

  return api.create("/arquivo", formData, {
    headers: { "Content-type": "multipart/form-data" },
  });
};

const apiSingleFile = (file: Blob) => {
  const formData = new FormData();
  formData.append("arquivos", file, "image.png");

  formData.append("tipoarquivo_id", "1");

  return api.create("/arquivo", formData, {
    headers: { "Content-type": "multipart/form-data" },
  });
};

export {
  apiGet,
  apiCreate,
  apiUpdate,
  apiDelete,
  apiFile,
  apiSingleFile,
  apiGetFile,
};
