import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

import { APICore } from '../helpers/api/apiCore';

import useUser from '../hooks/useUser'

interface PrivateRouteProps {
    component: React.FunctionComponent<RouteProps>;
    roles?: string;
    funcao: number | number[]
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, funcao, ...rest }: PrivateRouteProps) => {
    const api = new APICore();
    const user: any = useUser()

    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps) => {
                if (api.isUserAuthenticated() === false) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props['location'] },
                            }}
                        />
                    );
                }

                const loggedInUser = api.getLoggedInUser();

                const hasAccess = typeof funcao === 'object' ? 
                    funcao.map((item) => loggedInUser.grupo_sistema.funcoes.find(
                        (funcaoItem: any) => funcaoItem.id === item
                    )) :
                    loggedInUser.grupo_sistema.funcoes.find(
                        (funcaoItem: any) => funcaoItem.id === funcao
                    );
                

                if (typeof funcao === 'number' && !hasAccess) {
                    return <Redirect to={{ pathname: "/" }} />;
                }

                if (typeof funcao === 'object' && !hasAccess.find((item: undefined | any) => item !== undefined)) {
                    return <Redirect to={{ pathname: "/" }} />;
                }

                // check if route is restricted by role
                if (roles && roles === 'Admin' && user.expositor?.id) {
                    // role not authorised so redirect to home page
                    return <Redirect to={{ pathname: '/' }} />;
                }
                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
