import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

const ListagemUsuarios = React.lazy(
  () => import("../pages/apps/Usuarios/Usuarios")
);

const CriarUsuarios = React.lazy(
  () => import("../pages/apps/Usuarios/Usuarios/CriarUsuario")
);

const AtualizarUsuarios = React.lazy(
  () => import("../pages/apps/Usuarios/Usuarios/AtualizarUsuario")
);

const ListagemExpositores = React.lazy(
  () => import("../pages/apps/Expositores")
);

const AtualizarExpositores = React.lazy(
  () => import("../pages/apps/Expositores/AtualizarExpositor")
);

const ListagemEventos = React.lazy(() => import("../pages/apps/Eventos"));

const AtualizarEventos = React.lazy(
  () => import("../pages/apps/Eventos/AtualizarEvento")
);

const ExpositorVisualizarEventos = React.lazy(
  () => import("../pages/apps/Eventos/ExpositorVisualizarEvento")
);

const ListagemGrupoAcesso = React.lazy(
  () => import("../pages/apps/Usuarios/GrupoAcesso")
);

const AtualizarGrupoAcesso = React.lazy(
  () => import("../pages/apps/Usuarios/GrupoAcesso/Update")
);

const CriarGrupoAcesso = React.lazy(
  () => import("../pages/apps/Usuarios/GrupoAcesso/CriarGrupo")
);

const ListagemRepresentantes = React.lazy(
  () => import("../pages/apps/Configuracoes/Representantes/Listagem")
);

const CriarRepresentantes = React.lazy(
  () => import("../pages/apps/Configuracoes/Representantes/Listagem/CriarRepresentante")
);

const AtualizarRepresentantes = React.lazy(
  () => import("../pages/apps/Configuracoes/Representantes/Listagem/Update")
);

const ListagemEventosRepresentantes = React.lazy(
  () => import("../pages/apps/Configuracoes/Representantes/Eventos")
);

const ListagemFuncoes = React.lazy(
  () => import("../pages/apps/Configuracoes/Funcoes")
);

const ListagemCategorias = React.lazy(
  () => import("../pages/apps/Configuracoes/Categorias")
);

const MeuPerfil = React.lazy(() => import("../pages/apps/MeuPerfil"));

const MeuCadastro = React.lazy(() => import("../pages/apps/MeuCadastro"));

const MinhasCategorias = React.lazy(
  () => import("../pages/apps/MinhasCategorias")
);

const TipoDocumento = React.lazy(
  () => import("../pages/apps/Configuracoes/TipoDocumento")
);

const ResetarSenha = React.lazy(() => import("../pages/auth/ResetarSenha"));

const Relatorios = React.lazy(() => import("../pages/apps/Relatorios"));

const ListagemContratos = React.lazy(() => import("../pages/apps/Contratos"));
const CriarContrato = React.lazy(
  () => import("../pages/apps/Contratos/CriarContrato")
);
const AtualizarContrato = React.lazy(
  () => import("../pages/apps/Contratos/AtualizarContrato")
);
const ListagemContratosFornecedores = React.lazy(
  () => import("../pages/apps/ContratosFornecedores")
);
const CriarContratoFornecedores = React.lazy(
  () => import("../pages/apps/ContratosFornecedores/CriarContrato")
);
const AtualizarContratoFornecedores = React.lazy(
  () => import("../pages/apps/ContratosFornecedores/AtualizarContrato")
);

const Notificacoes = React.lazy(
  () => import("../pages/apps/Configuracoes/Notificacoes")
);

const ChecklistEvento = React.lazy(() => import("../pages/apps/Tasks/Tasks"));

const DetalhesChecklist = React.lazy(
  () => import("../pages/apps/Tasks/Details")
);

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  component?: RouteProps["component"];
  route?: any;
  exact?: RouteProps["exact"];
  icon?: string;
  header?: string;
  roles?: string;
  children?: RoutesProps[];
  funcao?: number | number[];
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: () => <Root />,
  route: Route,
};

// Usuário
const rotaListagemUsuario: RoutesProps = {
  path: "/usuarios/listagem",
  name: "Usuários",
  route: PrivateRoute,
  roles: "Admin",
  component: ListagemUsuarios,
  funcao: [10, 30],
};

const rotaCriarUsuario: RoutesProps = {
  path: "/usuarios/criar",
  name: "Usuários",
  route: PrivateRoute,
  roles: "Admin",
  component: CriarUsuarios,
  funcao: 11,
};

const rotaTask: RoutesProps = {
  path: "/checklist-eventos/:eventoId",
  name: "Checklist Eventos",
  route: PrivateRoute,
  roles: "Admin",
  component: ChecklistEvento,
  funcao: 53,
  exact: true,
};

const rotaAtualizarUsuario: RoutesProps = {
  path: "/usuarios/atualizar/:id",
  name: "Usuários",
  route: PrivateRoute,
  roles: "Admin",
  component: AtualizarUsuarios,
  funcao: 12,
};

const rotaListagemGrupoAcesso: RoutesProps = {
  path: "/grupo-acesso/listagem",
  name: "Grupos de acesso",
  route: PrivateRoute,
  roles: "Admin",
  component: ListagemGrupoAcesso,
  funcao: 14,
};

const rotaDetalhesChecklistEvento: RoutesProps = {
  path: "/checklist-eventos/:eventoId/detalhes/:tarefaid",
  name: "Detalhes Checklist",
  route: PrivateRoute,
  roles: "Admin",
  component: DetalhesChecklist,
  funcao: 53,
};

const rotaAtualizarGrupoAcesso: RoutesProps = {
  path: "/grupo-acesso/atualizar/:id",
  name: "Grupos de acesso",
  route: PrivateRoute,
  roles: "Admin",
  component: AtualizarGrupoAcesso,
  funcao: 16,
};

const rotaCriarGrupoAcesso: RoutesProps = {
  path: "/grupo-acesso/criar",
  name: "Grupos de acesso",
  route: PrivateRoute,
  roles: "Admin",
  component: CriarGrupoAcesso,
  funcao: 15,
};

const rotaListagemExpositores: RoutesProps = {
  path: "/expositores/listagem",
  name: "Expositores",
  route: PrivateRoute,
  roles: "Admin",
  component: ListagemExpositores,
  funcao: 5,
};

const rotaAtualizarExpositores: RoutesProps = {
  path: "/expositores/atualizar/:id",
  name: "Expositores",
  route: PrivateRoute,
  roles: "Admin",
  component: AtualizarExpositores,
  funcao: 5,
};

const rotaListagemEventos: RoutesProps = {
  path: "/eventos/listagem",
  name: "Eventos",
  route: PrivateRoute,
  component: ListagemEventos,
  funcao: 1,
};

const rotaAtualizarEventos: RoutesProps = {
  path: "/eventos/atualizar/:id",
  name: "Atualizar Evento",
  route: PrivateRoute,
  component: AtualizarEventos,
  funcao: [3, 50],
};

const rotaExpositorVisualizarEventos: RoutesProps = {
  path: "/eventos/visualizar/:id",
  name: "Visualizar Evento",
  route: PrivateRoute,
  component: ExpositorVisualizarEventos,
  //funcao: 3
};

const rotaListagemRepresentantes: RoutesProps = {
  path: "/representantes/listagem",
  name: "Representantes",
  route: PrivateRoute,
  component: ListagemRepresentantes,
  funcao: 56,
};

const rotaCriarRepresentantes: RoutesProps = {
  path: "/representantes/criar",
  name: "Representantes",
  route: PrivateRoute,
  component: CriarRepresentantes,
  funcao: 56,
};

const rotaAtualizarRepresentantes: RoutesProps = {
  path: "/representantes/atualizar/:id",
  name: "Representantes",
  route: PrivateRoute,
  component: AtualizarRepresentantes,
  funcao: 56,
};

const rotaListagemEventosRepresentantes: RoutesProps = {
  path: "/representantes/eventos/listagem",
  name: "Eventos de representantes",
  route: PrivateRoute,
  component: ListagemEventosRepresentantes,
  funcao: 56,
};

const rotaListagemFuncoes: RoutesProps = {
  path: "/funcoes/listagem",
  name: "Funções",
  route: PrivateRoute,
  component: ListagemFuncoes,
  funcao: 23,
};

const rotaListagemCategorias: RoutesProps = {
  path: "/categorias/listagem",
  name: "Categorias",
  route: PrivateRoute,
  component: ListagemCategorias,
  funcao: 19,
};

const rotaNotificacoes: RoutesProps = {
  path: "/notificacoes",
  name: "Notificacoes",
  route: PrivateRoute,
  component: Notificacoes,
  funcao: 51,
};

const rotaMeuCadastro: RoutesProps = {
  path: "/meu-cadastro",
  name: "Meu Cadastro",
  route: PrivateRoute,
  component: MeuCadastro,
  funcao: 27,
};

const rotaMinhasCategorias: RoutesProps = {
  path: "/minhas-categorias",
  name: "Minhas Categorias",
  route: PrivateRoute,
  component: MinhasCategorias,
  funcao: 31,
};

const rotaListagemTipoDocumento: RoutesProps = {
  path: "/tipo-documento/listagem",
  name: "Tipo Documento",
  route: PrivateRoute,
  component: TipoDocumento,
  funcao: 32,
};

const rotaMeuPerfil: RoutesProps = {
  path: "/meu-perfil",
  name: "Meu Perfil",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/meu-perfil",
      name: "Meu Perfil",
      exact: true,
      component: MeuPerfil,
      route: PrivateRoute,
    },
  ],
};

const rotaRelatorios: RoutesProps = {
  path: "/relatorios",
  name: "Relatórios",
  route: PrivateRoute,
  roles: "Admin",
  component: Relatorios,
  funcao: 9,
};

const rotaListagemContratos: RoutesProps = {
  path: "/contratos/clientes/listagem",
  name: "Contratos",
  route: PrivateRoute,
  roles: "Admin",
  component: ListagemContratos,
};
const rotaCriarContrato: RoutesProps = {
  path: "/contratos/clientes/criar",
  name: "Criar Contrato",
  route: PrivateRoute,
  roles: "Admin",
  component: CriarContrato,
};
const rotaAtualizarContrato: RoutesProps = {
  path: "/contratos/clientes/atualizar/:id",
  name: "Atualizar Contrato",
  route: PrivateRoute,
  roles: "Admin",
  component: AtualizarContrato,
};
const rotaListagemContratosFornecedores: RoutesProps = {
  path: "/contratos/fornecedores/listagem",
  name: "Contratos Fornecedores",
  route: PrivateRoute,
  roles: "Admin",
  component: ListagemContratosFornecedores,
};
const rotaCriarContratoFornecedores: RoutesProps = {
  path: "/contratos/fornecedores/criar",
  name: "Criar Contrato Fornecedor",
  route: PrivateRoute,
  roles: "Admin",
  component: CriarContratoFornecedores,
};
const rotaAtualizarContratoFornecedores: RoutesProps = {
  path: "/contratos/fornecedores/atualizar/:id",
  name: "Atualizar Contrato Fornecedores",
  route: PrivateRoute,
  roles: "Admin",
  component: AtualizarContratoFornecedores,
};

const appRoutes = [
  rotaMeuPerfil,
  rotaTask,
  rotaListagemUsuario,
  rotaDetalhesChecklistEvento,
  rotaCriarUsuario,
  rotaAtualizarUsuario,
  rotaListagemGrupoAcesso,
  rotaAtualizarGrupoAcesso,
  rotaCriarGrupoAcesso,
  rotaListagemExpositores,
  rotaAtualizarExpositores,
  rotaListagemEventos,
  rotaAtualizarEventos,
  rotaListagemRepresentantes,
  rotaCriarRepresentantes,
  rotaAtualizarRepresentantes,
  rotaListagemEventosRepresentantes,
  rotaListagemFuncoes,
  rotaListagemCategorias,
  rotaMeuCadastro,
  rotaMinhasCategorias,
  rotaListagemTipoDocumento,
  rotaExpositorVisualizarEventos,
  rotaRelatorios,
  rotaListagemContratos,
  rotaCriarContrato,
  rotaAtualizarContrato,
  rotaNotificacoes,
  rotaListagemContratosFornecedores,
  rotaCriarContratoFornecedores,
  rotaAtualizarContratoFornecedores,
];

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    component: Register,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
  {
    path: "/esqueceu-senha/:hash",
    name: "Atualizar a senha",
    component: ResetarSenha,
    exact: true,
    route: Route,
  },
  {
    path: "/esqueceu-senha",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    component: SignInSignUp,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, ...appRoutes];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
