import React, { createContext, useContext, useState } from "react";

interface IExpositorAlert {
    alertDisplay: boolean;
    setAlertDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

const ExpositorAlertContext = createContext({} as IExpositorAlert);

const ExpositorAlertProvider: React.FC = ({ children }) => {
    const [alertDisplay, setAlertDisplay] = useState(false);

    return (
        <ExpositorAlertContext.Provider
            value={{alertDisplay, setAlertDisplay}}
        >
            {children}
        </ExpositorAlertContext.Provider>
    );
};

const useExpositorAlert = () => {
    const expositorAlert = useContext(ExpositorAlertContext);
    return expositorAlert;
};

export { ExpositorAlertProvider, useExpositorAlert };
