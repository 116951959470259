import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { LoadingProvider } from './hooks/useLoading';
import { ExpositorAlertProvider } from './hooks/useExpositorAlert';
import { configureStore } from './redux/store';

ReactDOM.render(
    <Provider store={configureStore({})}>
        <LoadingProvider>
            <ExpositorAlertProvider>
                <App />
            </ExpositorAlertProvider>
        </LoadingProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
